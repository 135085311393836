<template>
  <b-modal :id="modalId" :ref="modalId" :title="$t('Localidades')" size="lg" hide-footer
   no-close-on-esc no-close-on-backdrop centered @close="close">
  <div class="row">
    <div class="col-12 col-lg-12 pb-1" style="max-height: 60vh; overflow-y: scroll">
      <span v-for="(chip, index) in chips" :key="`saved_${index}`">
        <h5 v-if="chip.title" class="mb-02 mt-05">{{chip.title}}</h5>
        <b-badge pill :variant="'secondary'" class="mr-04">
          {{chip.name}}
        </b-badge>
      </span>
    </div>
  </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import internalLevels from './internalLevels'
export default {
  name: 'modal-location-lock',
  props: {
    modalId: { type: String },
    setting: { type: Object }
  },
  data () {
    return {
      fields: [],
      keyFormRender: 0,
      keyCheckbox: 0,
      form: {},
      checkboxes: [],
      valueAll: [],
      chips: [],
      optionSelectAll: [{id: true, text: 'Marcar todos'}],
      country: {},
      chipsByStatus: {
        created: [],
        deleted: [],
        saved: []
      },
      internalLevels
    }
  },
  computed: {
    ...mapGetters({
      formattedLevels: 'getFormattedLevels'
    })
  },
  watch: {
    setting () {
      this.setFormattedLevels()
      this.rule_id = this.$route.params.rule_id === 'null' ? null : parseInt(this.$route.params.rule_id)
      let places = null
      if (this.selectorsLevel[`level${this.maxLevel}`] && this.selectorsLevel[`level${this.maxLevel}`].length) {
        places = this.setting?.places ? this.selectorsLevel[`level${this.maxLevel}`].filter(place => {
          return this.setting.places.map(el => el.id).includes(place.id)
        }) : null
      } else {
        places = this.setting?.places ? this.setting.places.map(place => {
          const currentName = place[`level${this.maxLevel}`] || place.name || place.text
          return {
            ...place,
            id: place.id,
            text: currentName,
            [`level${this.maxLevel}`]: currentName,
            name: currentName
          }
        }) : null
      }
      this.form[`level${this.maxLevel}_location`] = places || []
      this.createChips()
    },
    formattedLevels () {
      this.setFormattedLevels()
    }
  },
  mounted () {},
  methods: {
    setFormattedLevels () {
      this.selectorsLevel = {
        ...this.formattedLevels
      }
      this.country = this.selectorsLevel.country
      this.maxLevel = Math.max(...this.internalLevels[this.country.code.toLowerCase()].required)
      delete this.selectorsLevel.country
      this.setPlaces(this.country)
    },
    getCurrentChipList (list, bool) {
      return list.filter(el =>  el[bool])
    },
    getCurrentSelectorsLevel () {
      if (this.selectorsLevel[`level${this.maxLevel}`] && this.selectorsLevel[`level${this.maxLevel}`].length) {
        return this.selectorsLevel
      } else {
        return this.$generatePlacesLevels(this.form[`level${this.maxLevel}_location`], this.maxLevel, this.internalLevels[this.country.code.toLowerCase()].display)
      }
    },
    createChips () {
      const selectorsLevel = this.getCurrentSelectorsLevel()
      if (this.form && this.form[`level${this.maxLevel}_location`] && this.setting && this.setting.places) {
        const myPlaces = this.$getUniqueList([...this.form[`level${this.maxLevel}_location`]], 'id')
          .sort((a, b) => a.id > b.id ? 1 : -1)
        this.chips = []
        let chips = []
        const idsSaved = this.setting.places.map(el => el.id)
        if (myPlaces) {
          chips = myPlaces.map(el => {
            el.title = this.titleChip(el, selectorsLevel)
            el.order = `${el.title}, ${el.name}`
            el.saved = idsSaved.includes(el.id)
            el.created = !this.setting.places.map(el => el.id).includes(el.id)
            return el
          })
          chips = [...chips].sort((a, b) => a.order > b.order ? 1 : -1)
          let prevTitle = null
          chips = chips.map(el => {
            if (prevTitle === el.title) {
              delete el.title
            } else {
              prevTitle = el.title
            }
            return el
          })
        }
        this.chips = chips
        this.chipsByStatus.saved = [...chips].filter(el => el.saved)
        this.chipsByStatus.created = [...chips].filter(el => el.created)
      } else {
        this.chips = []
      }
    },
    titleChip (chip, selectorsLevel) {
      let title = []
      let bool = false
      Object.keys(selectorsLevel).map(key => {
        if (key !== `level${this.maxLevel}`) {
          title.push(chip[key])
          if (chip[`title${key}`]) bool = true
        }
      })
      title = title.filter(el => el !== '' && !!el)
      return title.join(', ')
    },
  
    close () {
      this.form = {}
      this.$emit('update:setting', {})
    },
    setPlaces (value) {
      this.getLevels(value)
    },
    getLevels (currentCountry) {
      const country = {
        code: currentCountry.code.toLowerCase(),
        requiredLevels: this.internalLevels[currentCountry.code.toLowerCase()].required,
        displayLevels: this.internalLevels[currentCountry.code.toLowerCase()].display,
        maxLevel: Math.max(...this.internalLevels[currentCountry.code.toLowerCase()].required),
        levels: this.selectorsLevel.levels,
        loadLevelsOnSelection: false,
        stepByStep: ['mx'].includes(this.country.code.toLowerCase())
        // level1Search: this.searchLevels
      }
      const field = { 
        fieldType: 'FieldsLevels', name: 'levels', extraName: 'location', 
        country, 
        change: this.changePlaceLevel,
        customMaxLevel: {
          useCheckbox: true,
          loadOnAllSelectors: true,
          specialProps: {
            useCheckAll: true,
            containerClass: 'col-12',
            class: 'mt-1',
            align: 'h',
            optionClass: 'col-12 col-md-4 col-lg-3 mr-0'
          }
        },
        containerClass: 'col-12 container-info col-md-6'
      }
      this.fields = [field]
    }
  }
}
</script>
<style lang="">
  
</style>