<template>
  <div>
    <b-card :class="watchers.disabledCard ? 'card-disabled' : 'card'">
      <div class="container-title-card">
        <h4 class="mb-1">{{conditionIndex === 0 ? $t('Condición por defecto') : form.name === '' || form.name === null ? `${$t('Condición')} ${conditionIndex}` : form.name }}</h4>
        <div>
          <b-button v-if="condition.rule_id" v-show="!watchers.disabledCard"
              variant="outline-success" v-b-tooltip :title="$t('Guardar condición')"
              @click="updateCondition"
            >
              <feather-icon icon="SaveIcon"/>
          </b-button>
          <b-button v-show="editingRule ? !editingRule : watchers.editableRule" :disabled="watchers.editingRule"
              variant="outline-warning" v-b-tooltip :title="$t('Editar condición')" @click="ableToEdit()">
              <feather-icon icon="EditIcon"/>
          </b-button>
          <b-button v-if="!(conditionIndex === 0)" v-show="editingRule ? !editingRule : watchers.editableRule" :disabled="watchers.editingRule"
              variant="outline-danger" class="ml-1" v-b-tooltip :title="$t('Eliminar condición')" @click="deleteCondition(condition.id)">
              <feather-icon icon="TrashIcon"/>
          </b-button>
           <b-button v-if="editingRule" v-show="condition.rule_id"
              variant="outline-danger" class="ml-1" v-b-tooltip :title="$t('Cancelar edición')" @click="cancelEdit()">
              <feather-icon icon="XIcon"/>
          </b-button>
        </div>
      </div>
      <form-render :form.sync="form" :key="keyFormRender" :fields="fieldsConditions" :ref="`formCondition-${conditionIndex}`" >
        <template #myButtons>
          <b-button :disabled="!!(watchers.disabledAdd)" v-show="editingRule"
            variant="outline-warning" class="mr-1"
            @click="createSetting()" :title="$t('Agregar sub condición')">
            <font-awesome-icon :icon="['fa', 'plus']"/>
          </b-button>
        </template>
        <template #settings>
          <div v-for="(setting, settingIndex) in settings" :key="settingIndex">
            <rule-settings :ref="`setting-${settingIndex}-${conditionIndex}`" v-if="!(conditionIndex === 0)" :setting="setting" :index="settingIndex" :conditionIndex="conditionIndex" :rule_id="condition.rule_id" 
              :isLastOne="!!(settingIndex === (settings.length - 1))"
              :isFirstOne="!(settingIndex === 0) || (settings.length > 1)"
              :conditionDisabled="watchers.disabledCard" :country.sync="country"
              :entity.sync="entity" :entity_id.sync="entity_id"
              :ruleTypes="ruleTypes"
              :usedRuleTypes="usedRuleTypes"
              :optionRuleByHour="optionRuleByHour"
              @remove="deleteSetting" @emitWatcher="emitWatcher" @update="updateSetting" @saveCondition="updateCondition">
            </rule-settings>
          </div>
        </template>
        <!-- <template #alert>
          <b-alert :show="showAlert[`alert-${conditionIndex}`]" variant="danger" class="p-1 mb-0"> Seleccione un courier y su servicio!! </b-alert>
        </template> -->
      </form-render>
    </b-card>
  </div>
</template>
<script>
import _ from 'lodash'
import RuleSettings from './RuleSettings.vue'
import RuleServices from '@/views/modules/advanced-rules/advancedRules.service'
import * as moment from 'moment'
export default {
  name: 'rule-conditions',
  components: { RuleSettings },
  props: {
    conditions: { type: Array },
    condition: { type: Object },
    conditionIndex: { type: Number },
    carriers: { type: Array },
    country: { type: Object },
    entity: { type: String },
    entity_id: { type: Number },
    ruleTypes: { type: Array },
    outputTypes: { type: Array },
    disables: { type: Object }
  },
  data() {
    return {
      form: {},
      keyCondition: 0,
      keyFormRender: 0,
      fieldsConditions: [],
      watchers: {
        editing: false,
        disabledAdd: true,
        disabledCard: false,
        editableRule: false,
        editingRule: false
      },
      editingRule: false,
      usedRuleTypes: [],
      optionRuleByHour: [],
      // showAlert: {
      //   [`alert-${this.conditionIndex}`]: true
      // },
      specialConfigurations: {},
      ruleService: new RuleServices(this)
    }
  },
  computed: {
    settings: {
      get() {
        if (this.form.settings) {
          const newSettings = this.form.settings.map(setting => { 
            return {
              ...setting.input,
              ...setting 
            }
          })
          return newSettings
        } else return []
      },
      set(settings) {
        this.form.settings = settings
      }
    }
  },
  mounted () {
    if (this.disables) {
      this.watchers.disabledCard = this.editingRule ? this.emitWatcher({ disabledCard: false }) : this.disables.disabledCard
      this.watchers.editableRule = this.disables.editableRule
      this.watchers.editingRule = !!this.disables.editingRule
    }
    this.createArrayTime()
    if (Array.isArray(this.outputTypes) && this.outputTypes.length !== 0) { 
      this.outputOption = this.outputTypes
    }
    if (this.condition?.isNewCondition) {
      this.editingRule = this.condition?.isNewCondition
      this.emitWatcher({ disabledCard: false })
    }
    this.setForm('Mounted')
  },
  watch: {
    condition () {
      this.setForm('Watch')
    },
    carriers () {
      this.setSelectOptions(this.fieldsConditions, 'carrier', { options: this.carriers })
      if (this.form.carrier && (this.form.carrier !== '' || this.form.carrier !== {})) {
        const carrier = this.carriers.filter(carrier => carrier.id === this.form.carrier.id)[0]
        if (!!carrier) this.setOptionsPropertiesCarrier(carrier.properties)
      }
    },
    settings () {
      this.usedRuleTypes = this.settings.map(el => el.rule_type_id)
    },
    disables () {
      this.watchers.disabledCard = this.editingRule ? this.emitWatcher({ disabledCard: false }) : this.disables.disabledCard
      this.watchers.editableRule = this.disables.editableRule
      this.watchers.editingRule = !!this.disables.editingRule
      this.setInitialData()
    },
    outputTypes() {
      this.outputOption = this.outputTypes
    }, 
    'form.services' () {
      if (this.form.carrier && this.form.services) {
        this.emitWatcher({ disabledAdd: false })
      } else { 
        this.emitWatcher({ disabledAdd: true })
      }
    },
    'form.output_checkbox' () {
      this.ableAddSetting()
    }
  },
  methods: {
    setInitialData() {
      this.fieldsConditions = [
        {fieldType: 'FieldRadio', name: 'output_checkbox', label: this.$t('Resolucion de regla por'), containerClass: 'col-sm-12 container-info', validation: 'required', align: 'h', options: this.conditionIndex === 0 ? this.outputOption?.filter(output => !!(output.activation_settings?.default_condition)).sort((a, b) => a.id < b.id ? -1 : 1) : this.outputOption.sort((a, b) => a.id < b.id ? -1 : 1), 
          change: this.changeCheckboxOutput, disabled: this.watchers.disabledCard }
      ]
      if (this.conditionIndex !== 0) {
        this.fieldsConditions.push(
          { name: 'myButtons', useSlot: true, containerClass: 'col-3 col-md-3 col-lg-3 mt-2' }, 
          { name: 'settings', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' })
      }
      this.changeCheckboxOutput()
      if ((this.form.carrier && this.form.services) || !!(this.form.output_checkbox?.code === 'rejected')) {
        this.emitWatcher({ disabledAdd: false })
      } else this.emitWatcher({ disabledAdd: true })
    },
    setForm(from) {
      this.form = {
        ...this.condition,
        ...this.condition.output
      }
      if (this.form.settings.length === 0) this.form.settings = []
      if (this.form.output === null) { 
        this.form.carrier = null
        this.form.outputKeys = ['carrier']
      } else {
        this.form.outputKeys = Object.keys(this.form.output)
      }
      delete this.form.output
      Object.keys(this.form).map(key => { 
        if (['carrier', 'old_rule_id', 'rejected', 'ignored'].includes(key)) {
          this.form.output_checkbox = this.outputOption?.filter(type => type.code === key)[0] || { code: key }
        }
      })
      this.setInitialData()
    },
    changeCheckboxOutput() {
      const index = this.fieldsConditions.findIndex(field => field.name === 'output_checkbox')
      if (index === -1) return this.$alert('Error on condition field: index == -1')
      this.fieldsConditions = this.fieldsConditions.filter(el => !['carrier', 'services', 'attribute', 'line0', 'old_rule_id', 'rejected', 'ignored'].includes(el.name))
      if (this.form.output_checkbox?.code === 'rejected') {
        this.form.rejected = this.outputOption.filter(type => type.code === 'rejected')[0] || { code: 'rejected', text: 'Creación con rechazo'}
        this.fieldsConditions.splice(index + 1, 0, {fieldType: 'FieldSelect', name: 'rejected', label: 'Valor', validation: 'required', containerClass: 'col-sm-12 col-md-4 col-lg-3 container-info', disabled: true })
      } else if (this.form.output_checkbox?.code === 'ignored') {
        this.form.ignored = this.outputOption.filter(type => type.code === 'ignored')[0] || { code: 'ignored', text: 'Ignorar creación'}
        this.fieldsConditions.splice(index + 1, 0, {fieldType: 'FieldSelect', name: 'ignored', label: 'Valor', validation: 'required', containerClass: 'col-sm-12 col-md-4 col-lg-3 container-info', disabled: true })
      } else if (this.form.output_checkbox?.code === 'old_rule_id') {
        this.fieldsConditions.splice(index + 1, 0, {fieldType: 'FieldInput', name: 'old_rule_id', label: 'ID Regla antigua', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', disabled: this.watchers.disabledCard })
      } else {
        this.form.output_checkbox = { code: 'carrier', text: 'Courier' }
        this.fieldsConditions.splice(index + 1, 0,
          {fieldType: 'FieldSelect', name: 'carrier', label: 'Courier', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', change: this.setCarrier, disabled: this.watchers.disabledCard },
          {fieldType: 'FieldSelect', name: 'services', label: 'Servicios', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', change: this.updateConditionOutput, disabled: this.watchers.disabledCard },
          {fieldType: 'FieldSelect', name: 'attribute', label: 'Atributos', dependency: 'carrier', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', change: this.updateConditionOutput, disabled: this.watchers.disabledCard }
        )
        if (this.carriers) { 
          this.setSelectOptions(this.fieldsConditions, 'carrier', { options: this.carriers })
          if (this.form.carrier) {
            const carrier = this.carriers.filter(carrier => carrier.code === this.form.carrier.code)[0]
            if (!!carrier) this.setOptionsPropertiesCarrier(carrier.properties)
          }
        }
      }
      if (!(Array.isArray(this.outputOption) && this.outputOption.length !== 0) || (this.outputOption.length <= 1)) {
        this.fieldsConditions = this.fieldsConditions.filter(field => field.name !== 'output_checkbox')
      }
      this.ableAddSetting()
      this.keyFormRender++
    },
    ableAddSetting() {
      if ((this.form.output_checkbox.code === 'carrier' && this.form.carrier && this.form.services) || (this.form.output_checkbox.code === 'rejected') || (this.form.output_checkbox.code === 'ignored') || (this.form.output_checkbox.code === 'old_rule_id' && !!this.form.old_rule_id)) {
        this.emitWatcher({ disabledAdd: false })
      } else { 
        this.emitWatcher({ disabledAdd: true })
      }
    },
    createSetting () {
      const newId = Math.max(...this.conditions.map(condition => Math.max(...condition.settings.map(setting => setting.id)))) + 1
      const newSetting = {
        rule_type_id: null,
        deleteId: true
      }
      if (newId === -Infinity) { 
        newSetting.id = 0
      } else { 
        newSetting.id = newId
      }
      this.form.settings.push(newSetting)
      this.emitWatcher({ disabledAdd: true })
    },
    deleteSetting (_id) {
      this.form.settings.splice(this.getElementIndex(this.form.settings, _id, 'id'), 1)
      if (this.form.settings[(this.form.settings.length - 1)].type !== null) this.emitWatcher({ disabledAdd: false })
    },
    updateSetting(setting, settingIndex, from = null) {
      if (from === 'input') this.form.settings[settingIndex] = setting
      else this.form.settings.splice(settingIndex, 1, setting)
      if (this.form.settings[(this.form.settings.length - 1)].type !== null) this.emitWatcher({ disabledAdd: false })
    },
    deleteCondition (_id) {
      this.$emit('remove', _id)
    },
    updateConditionOutput () {
      if (!(this.form.carrier && this.form.services)) { 
        this.$emit('watchers', { disabledCreateButton: true })
        return
      }
      this.$emit('updateOutput', { ...this.formatCondition(this.form), id: this.form.id }, this.conditionIndex)
      this.$emit('watchers', { disabledCreateButton: false })
    },
    async updateCondition () {
      if (this.form.output_checkbox?.code === 'old_rule_id' && (!this.form.old_rule_id || this.form.old_rule_id === '')) { 
        return this.$alert(this.$t('MISS_OLD_ID_RULE'))
      } else if (this.form.output_checkbox?.code === 'carrier' && !(this.form.carrier && this.form.services)) {
        return this.$alert(this.$t('MISS_CARRIER'))
      }
      if (Array.isArray(this.form.settings) && this.form.settings.length !== 0) {
        const abort = this.form.settings.map(setting => ([8, 3].includes(setting.rule_type_id) && Array.isArray(setting.input) && setting.input.length === 0))
        if (abort.includes(true)) return this.$alert(this.$t('MISS_PLACES'))
        const promises = []
        if (this.conditionIndex !== 0) {
          for (let index = 0; index < this.form.settings.length; index++) {
            promises.push(this.$refs[`setting-${index}-${this.conditionIndex}`][0].$refs[`formSetting-${index}-${this.conditionIndex}`].onlyCheckForm())
          }
        }
        const responsesPromises = await Promise.all(promises)
        if (responsesPromises.includes(false)) return
        const condition = this.formatCondition(this.form)
        this.$emit('update', condition, this.form.id, this.conditionIndex)
        this.editingRule = false
      } else return this.$alert(this.$t('MISS_SET_SETTINGS'))
    },
    formatCondition (condition) {
      let formatedCondition = {
        draft_status: condition?.draft_status || false,
        name: condition?.name || '',
        order: condition?.order,
        rule_id: condition?.rule_id
      }
      if (condition.output_checkbox?.code === 'old_rule_id') {
        formatedCondition = {
          ...formatedCondition,
          output: {
            old_rule_id: condition?.old_rule_id
          }
        }
      } else if (condition.output_checkbox?.code === 'rejected') {
        formatedCondition = {
          ...formatedCondition,
          output: {
            rejected: true
          }
        }
      } else if (condition.output_checkbox?.code === 'ignored') {
        formatedCondition = {
          ...formatedCondition,
          output: {
            ignored: true
          }
        }
      } else {
        if (condition.carrier) {
          formatedCondition.output = {
            ...formatedCondition.output,
            carrier: {
              id: condition.carrier?.id || null,
              code: condition.carrier?.code || null,
              text: condition.carrier?.text || null  
            }
          }
        } 
        else {
          formatedCondition.output = {
            ...formatedCondition.output,
            carrier: null
          }
        }
        if (condition.services) {
          formatedCondition.output = {
            ...formatedCondition.output,
            services: {
              id: condition.services?.id || null,
              code: condition.services?.code || null,
              text: condition.services?.text || null  
            }
          }
        } 
        if (condition.attribute) {
          formatedCondition.output = {
            ...formatedCondition.output,
            attribute: {
              id: condition.attribute?.id || null,
              code: condition.attribute?.code || null,
              text: condition.attribute?.text || null
            }
          }
        }
      }
      condition.outputKeys.filter(el => !['carrier', 'services', 'attribute', 'old_rule_id', 'rejected', 'ignored'].includes(el)).map(key => formatedCondition.output[key] = condition[key])
      if (condition.settings) {
        formatedCondition.settings = condition.settings.map(setting => {
          if (setting.deleteId) {
            delete setting.deleteId
            delete setting.id
            return {
              ...setting
            }
          } else return {
            ...setting
          }
        })
      }
      return formatedCondition
    },
    emitWatcher (values) {
      Object.keys(values).map(key => this.watchers[key] = values[key]) 
    },
    setSelectOptions (fields, name, { options }) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index].options = options
      }
      this.keyFormRender++
    },
    setCarrier (name, value) {
      this.form = {
        ...this.form
      }
      this.clearInput(this.form, this.fieldsConditions, 'services')
      this.clearInput(this.form, this.fieldsConditions, 'attribute')
      if (!value?.id) return
      this.setOptionsPropertiesCarrier(value.properties)
    },
    setOptionsPropertiesCarrier (props) {
      this.setSelectOptions(this.fieldsConditions, 'services', { options: props.services.map(el => ({...el, text: el.name})) })
      this.setSelectOptions(this.fieldsConditions, 'attribute', { options: props.attributes.map(el => ({...el, text: el.name})) })
      /*this.setSelectOptions('types', { options: props.types.map(el => ({...el, text: el.name})) })*/
    },
    getElementIndex(array, value, property) {
      return array.findIndex(field => field[property] === value)
    },
    clearInput(form, fields, name) {
      delete form[name]
      fields[this.getElementIndex(fields, name, 'name')].options = []
    },
    ableToEdit() {
      this.formBeforeEdit = _.cloneDeep({ ...this.form })
      this.editingRule = true
      this.$emit('watchers', { editingRule: true })
    },
    cancelEdit() {
      this.$emit('updateOutput', {...this.formatCondition(this.formBeforeEdit), id: this.formBeforeEdit.id }, this.conditionIndex)
      this.editingRule = false
      this.$emit('watchers', { disabledCard: true })
      this.$emit('watchers', { editingRule: false })
    },
    createArrayTime () {
      const startTime = moment('00:00', 'HH:mm:ss')
      const endTime = moment('23:30', 'HH:mm:ss')
      const timeIncrement = 30
      const timeArray = []
      const currentTime = startTime
      let i = 1
      while (currentTime.isSameOrBefore(endTime)) {
        const formattedTime = { id: i, text: currentTime.format('HH:mm:ss') }
        timeArray.push(formattedTime)
        currentTime.add(timeIncrement, 'minutes')
        i++
      }
      this.optionRuleByHour = timeArray
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 8px;
  border: 0.5px solid #4A7BBE;
  background-color: #F0F7FA;
}
.card-disabled {
  border-radius: 8px;
  border: 0.5px solid #E3E3E3;
  background-color: #f3f3f4;
}
.container-title-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>