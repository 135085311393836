import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get'},
  { name: 'getCarriersByShipper', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/carriers`, method: 'get' },
  { name: 'getCarriersByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/carriers`, method: 'get' },
  { name: 'createRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules`, method: 'post' },
  { name: 'getSettingRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings/{id}`, method: 'get'},
  { name: 'getSettingRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings/{id}`, method: 'get'},
  { name: 'getRuleTypes', url: `${environment.nippurApiUrl}/rule-types`, method: 'get', notCancel: true},
  { name: 'getOutputTypesOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/outputs`, method: 'get', notCancel: true},
  { name: 'getOutputTypesShipper', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/outputs`, method: 'get', notCancel: true},
  { name: 'getShippersAssigned', url: `${environment.nippurApiUrl}/shippers_by_rule/{rule_id}/`, method: 'get'},
  { name: 'updateRuleAssignedSeller', url: `${environment.nippurApiUrl}/organization/{organization_id}/shipper/{shipper_id}/rule/{rule_id}/active`, method: 'put'},
  { name: 'updateRuleAssignedOrganization', url: `${environment.nippurApiUrl}/organization/{organization_id}/rule/{rule_id}/active`, method: 'put'},
  // New Endpoints
  { name: 'getCarriers', url: `${environment.tokyoApiUrl}/v1/carriers/carrier-organization-shippers-new`, method: 'get', notCancel: true }
]

export default class AdvancedRulesService {
  constructor(context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  setEnvironment () {
    return environment.platformUrl
  }
}
