<template>
  <div class="col-sm-12 col-md-12 pb-1" :class="isLastOne ? 'pr-0' : 'main-container-setting ml-1'">
    <form-render :fields="fields" :form="form" :key="`keySetting${keyFormRender}`" :ref="`formSetting-${index}-${conditionIndex}`" :class="isLastOne ? 'main-container-setting-lastone pl-1' : ''">
      <template #places>
          <div>
            <h6 v-if="form && form.count_rule_setting_input && !form.uploadFile" style="font-size: 0.9rem" :class="{'text-danger': (form && form.count_rule_setting_input ? form.count_rule_setting_input : 0 ) === 0}">{{ form && form.count_rule_setting_input ? form.count_rule_setting_input : 0  }} localidades añadidas</h6>
            <!-- <h6 v-else-if="form.input === []" style="font-size: 0.9rem" class="text-danger">{{typeof form.input}} No hay localidades añadidas ni archivo de carga masiva</h6>-->
            <h6 v-if="form.uploadFile" style="font-size: 0.9rem"> Hay un archivo de localidades cargado </h6> 
          </div>
      </template>
      <template #mybuttons>
        <b-button v-if="form.type && (form.type.id === 3 || form.type.id === 8)" variant="primary" class="mr-2" @click="openModalLocations()" :disabled="loading[`location-${index}`]">
          <feather-icon v-if="loading[`location-${index}`]" icon="LoaderIcon" class="spinner mr-1" size="1x"/>
          <feather-icon icon="MapIcon"/> {{ settingDisabled ? 'Ver' : 'Seleccionar' }} localidades
        </b-button>
        <b-button v-if="form.type && (form.type.id === 3 || form.type.id === 8)" v-show="!settingDisabled" variant="success" class="mr-2" @click="openModalUpload()">
          <feather-icon icon="UploadCloudIcon"/> Carga masiva
        </b-button>
        <b-button v-if="form.type && !!isFirstOne" v-show="!settingDisabled"
          variant="outline-danger" v-b-tooltip :title="$t('Eliminar tipo de regla')" class="mr-2" @click="deleteSetting(form.id)" >
          <feather-icon icon="TrashIcon"/>
        </b-button>
      </template>
    </form-render>
    <div v-if="form.type && (form.type.id === 3 || form.type.id === 8)" >
      <modal-locations :modalId="`modalLocations-setting-${conditionIndex}-${index}`" :setting.sync="currentSetting" :entityKey.sync="entity" :entityValue.sync="entity_id" @update="updateSettingPlaces"></modal-locations>
      <modal-locations-lock :modalId="`modalLocations-lock-setting-${conditionIndex}-${index}`" :setting.sync="currentSetting"></modal-locations-lock>
      <modal-locations-by-code :modalId="`modalLocationsByCode-setting-${conditionIndex}-${index}`" :setting.sync="currentSettingByCode" :entityKey.sync="entity" :entityValue.sync="entity_id" @update="updateSettingPlaces"></modal-locations-by-code>  
      <modal-upload-location :modalId="`modalUploadLocation-setting-${conditionIndex}-${index}`" :setting.sync="currentSettingByUpload" @postUploadFile="updateSettingPlaces" @getPlaceTemplate="getPlaceTemplate" :loading="loading.uploadLocation"></modal-upload-location>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import internalLevels from './internalLevels'
import ModalLocations from './../create/ModalLocations.vue'
import ModalLocationsLock from './../create/ModalLocationsLock.vue'
import ModalUploadLocation from './../create/ModalUploadLocation.vue'
import ModalLocationsByCode from './../create/ModalLocationsByCode.vue'
import RuleServices from '@/views/modules/advanced-rules/advancedRules.service'
import BaseServices from '@/store/services'

export default {
  name: 'rule-settings',
  components: { ModalLocations, ModalLocationsByCode, ModalUploadLocation, ModalLocationsLock },
  props: {
    entity: { type: String },
    entity_id: { type: Number },
    index: { type: Number },
    conditionIndex: { type: Number },
    setting: { type: Object },
    rule_id: { type: Number },
    country: { type: Object },
    ruleTypes: { type: Array },
    usedRuleTypes: { type: Array },
    optionRuleByHour: { type: Array },
    specialConfigurations: { type: Object },
    isLastOne: { type: Boolean },
    isFirstOne: { type: Boolean },
    conditionDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      form: {},
      dataLocations: {},
      fields: [],
      keyFormRender: 0,
      currentSetting: null,
      currentSettingByCode: null,
      currentSettingByUpload: null,
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      internalLevels,
      optionRuleByDay: [
        {id: 1, text: 'Lunes', code: 'monday'},
        {id: 2, text: 'Martes', code: 'tuesday'},
        {id: 3, text: 'Miercoles', code: 'wendnesday'},
        {id: 4, text: 'Jueves', code: 'thursday'},
        {id: 5, text: 'Viernes', code: 'friday'},
        {id: 6, text: 'Sabado', code: 'saturday'},
        {id: 7, text: 'Domingo', code: 'sunday'}
      ],
      loading: {
        uploadLocation: false,
        levels: true,
        [`location-${this.index}`]: false,
        total: true
      },
      [`places-location-condition-${this.conditionIndex}-setting-${this.index}`]: false,
      settingDisabled: false,
      firstTime: true,
      ruleService: new RuleServices(this),
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      levels: 'getLevels',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    setting () {
      this.setSetting('watch')
    },
    levels () {
      this.loadedData()
    },
    ruleTypes () {
      this.optionsRuleType = this.ruleTypes
    },
    conditionDisabled () {
      this.settingDisabled = this.conditionDisabled
    },
    generalLoading: {
      handler() {
        this.loading.levels = !!this.generalLoading.getLevels
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total' || key !== `location-${this.index}`).every(key => !this.loading[key])
        // Que la actualización del componente solo se fuerce cuando esté todo cargado
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    }
  }, 
  mounted() {
    this.settingRuleService = this.entity === 'organization_id' ? 'SettingRuleByOrganization' : 'SettingRule'
    this.settingUploadFileService = this.entity === 'shipper_id' ? 'UploadFileLocation' : 'UploadFileLocationByOrganization'
    if (this.ruleTypes) {
      this.optionsRuleType = this.ruleTypes
    }
    if (this.levels) {
      this.loadedData()
    }
    if (this.conditionDisabled) {
      this.settingDisabled = this.conditionDisabled
    }
    if (this.setting) {
      this.setSetting('mounted')
    }
  },
  methods: {
    setSetting (from) {
      if (this.firstTime) {
        this.originalSetting = this.formatedSettingToShow(this.setting)
        this.firstTime = false
      }
      this.form = {
        ...this.formatedSettingToShow(this.setting)
      }
      this.setTypeRule(false, this.form.type)
    },
    setTypeRule (save = false, value, showSaveAlert = false) {
      if (!!value && !value.active) { 
        this.settingDisabled = true
      }
      let optionRules = []
      const optionsTypes = this.filterSelectRuleType()
      this.fieldTypeForm = {
        fieldType: 'FieldSelect', name: 'type', label: 'Tipo de regla', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-3 container-info',
        clearable: false,
        options: optionsTypes,
        change: this.changeType,
        disabled: this.settingDisabled
      }
      this.fields = [this.fieldTypeForm, { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }]
      if (!value?.id) return
      this.fields = this.fields.filter(el => el.name !== 'mybuttons')
      switch (value.id) {
      case 2: // Peso
        optionRules = this.optionsRuleType.filter(field => field.id === value.id)[0].logic_operators
        this.fields.push(
          {fieldType: 'FieldSelect', name: 'operator', label: 'Rango', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', options: optionRules, 
            change: this.changeTypeOperator, disabled: this.settingDisabled }
        )
        if (this.form.operator) this.setOperatorType(false, this.form.operator, null, this.settingDisabled)
        break

      case 6: // Código regla
        this.fields.push(
          { fieldType: 'FieldInput', name: 'rule_code', label: 'Codigo de regla', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', 
            change: () => this.updateSetting('input'),  disabled: this.settingDisabled},
          { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }
        )
        break

      case 3: // Localidad de origen
        this.fields.push(
          { name: 'mybuttons', useSlot: true, containerClass: 'col-sm-12 col-md-8 col-lg-9 mt-2' },
          { name: 'places', useSlot: true, containerClass: 'col-12' }
        )
        break

      case 8: // Localidad de destino
        this.fields.push(
          { name: 'mybuttons', useSlot: true, containerClass: 'col-sm-12 col-md-8 col-lg-9 mt-2' },
          { name: 'places', useSlot: true, containerClass: 'col-12' }
        )
        break

      case 4: // Precio
        optionRules = this.optionsRuleType.filter(field => field.id === value.id)[0].logic_operators
        this.fields.push(
          {fieldType: 'FieldSelect', name: 'operator', label: 'Rango', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', options: optionRules, 
            change: this.changeTypeOperator,  disabled: this.settingDisabled }
        )
        if (this.form.operator) this.setOperatorType(false, this.form.operator, null, this.settingDisabled)
        break

      case 7: // Servicio
        this.fields.push(
          { fieldType: 'FieldInput', name: 'service_name', label: 'Nombre de servicio', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', 
            change: () => this.updateSetting('input'),  disabled: this.settingDisabled },
          { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }
        )
        break
      
      case 9: // Por dia de la semana Cenco
        this.fields.push(
          { fieldType: 'FieldSelect', name: 'rule_day', label: 'Dia de la semana', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', options: this.optionRuleByDay,
            change: () => this.updateSetting('input'),  disabled: this.settingDisabled },
          { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }
        )
        break
      case 10: // Por comodin
        this.fields.push(
          { fieldType: 'FieldTextarea', name: 'input', label: 'Campos evaluados', validation: 'required', containerClass: 'col-sm-12 container-info col-md-6', disabled: true },
          { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }
        )
        break
      case 11: // Por dia de la semana de creacion
        this.fields.push(
          { fieldType: 'FieldSelect', name: 'rule_day', label: 'Dia de la semana', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', options: this.optionRuleByDay, 
            change: () => this.updateSetting('input'),  disabled: this.settingDisabled },
          { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }
        )
        break
      case 12: // Por hora de creacion
        optionRules = this.optionsRuleType.filter(field => field.id === value.id)[0].logic_operators
        this.fields.push(
          {fieldType: 'FieldSelect', name: 'operator', label: 'Rango', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', options: optionRules, 
            change: this.changeTypeOperator,  disabled: this.settingDisabled }
        )
        if (this.form.operator) this.setOperatorType(false, this.form.operator, this.optionRuleByHour, this.settingDisabled)
        break
      case 13: // Por cantidad de bultos
        optionRules = this.optionsRuleType.filter(field => field.id === value.id)[0].logic_operators
        this.fields.push(
          {fieldType: 'FieldSelect', name: 'operator', label: 'Rango', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', options: optionRules, 
            change: this.changeTypeOperator, disabled: this.settingDisabled }
        )
        if (this.form.operator) this.setOperatorType(false, this.form.operator, null, this.settingDisabled)
        break
      default:
        break
      }
      if (save) this.updateSetting()
      this.keyFormRender++
    },
    changeType (name, value) {
      if (this.originalSetting.rule_type_id === this.form.type.id) {
        this.setTypeRule(true, value, false)
      } else this.setTypeRule(true, value, true)
    },
    changeTypeOperator (name, value) {
      this.form.max = null
      if (this.form.min) this.form.min = null
      this.setOperatorType(true, value, null, false)
    }, 
    setOperatorType (save = false, operator, options = null, isDisabled) {
      this.fields = this.fields.filter(field => ['type', 'operator'].includes(field.name))
      if (operator === null) return
      const fieldTypeMin = {fieldType: 'FieldInput', name: 'min', label: 'Mínimo', validation: 'required', containerClass: 'col-sm-12 col-md-3 col-lg-2 container-info', change: () => this.updateSetting('input'), disabled: isDisabled }
      const fieldTypeMax = { ...fieldTypeMin, name: 'max', label: 'Máximo' }
      if (this.form.type.id === 12) {
        [fieldTypeMin, fieldTypeMax].map(field => { 
          field.fieldType = 'FieldSelect'
          field.label = field.name === 'min' ? 'Desde las:' : 'Hasta las:'
          field.options = options
        })
      }
      switch (operator.code) {
      case 'between':
        this.fields.push(fieldTypeMin, fieldTypeMax, { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' })
        break
      default:
        fieldTypeMax.label = 'Valor'
        this.fields.push(fieldTypeMax, { name: 'mybuttons', useSlot: true, containerClass: 'col-12 col-md-3 col-lg-2 mt-2' }
        )
        break
      }
      if (save) this.updateSetting()
      this.keyFormRender++
    },
    deleteSetting(id) {
      this.$emit('remove', id)
    },
    updateSetting(from = null) {
      const formatedSetting = {
        id: this.form.id,
        rule_type_id: this.form.type.id,
        input: this.formatSettingInput(this.form)
      }
      if ([3, 8].includes(this.form.type.id)) { 
        formatedSetting.updatePlaces = this.form.updatePlaces || false
        formatedSetting.updatePlacesMx = this.form.updatePlacesMx || false
        formatedSetting.uploadFile = this.form.uploadFile || false
      }
      if (this.form.count_rule_setting_input) formatedSetting.count_rule_setting_input = this.form.count_rule_setting_input
      if (this.form.deleteId) formatedSetting.deleteId = true
      this.$emit('update', formatedSetting, this.index, from)
    },
    formatedSettingToShow (setting) {
      const formatedSetting = {
        id: setting.id || null,
        type: this.optionsRuleType.find(el => el.id === setting.rule_type_id) || null,
        ...setting
      }
      if (setting.parent_rule_setting_id) delete formatedSetting.parent_rule_setting_id
      if ([2, 4, 13].includes(formatedSetting.rule_type_id)) {
        formatedSetting.operator = formatedSetting.type.logic_operators.filter(operator => setting.operator === operator.code)[0]
      }
      if ([12].includes(formatedSetting.rule_type_id)) {
        Object.keys(formatedSetting.input).map(key => {
          if (key === 'operator') {
            formatedSetting[key] = formatedSetting.type.logic_operators.filter(operator => formatedSetting.input[key] === operator.code)[0]
          } else {
            formatedSetting[key] = this.optionRuleByHour.filter(hour => hour.text === formatedSetting.input[key])[0]
          }
        })
      }
      if ([3, 8].includes(formatedSetting.rule_type_id)) { 
        formatedSetting.updatePlaces = false
        formatedSetting.updatePlacesMx = false
        formatedSetting.uploadFile = false
      }
      if ([6].includes(formatedSetting.rule_type_id)) { 
        formatedSetting.rule_code = setting['x-rule-by-code']
        delete formatedSetting['x-rule-by-code']
      }
      if ([9].includes(formatedSetting.rule_type_id)) { 
        formatedSetting.rule_day = this.optionRuleByDay.filter(day => day.code === setting['x-deadline_date'])
        delete formatedSetting['x-deadline_date']
      }
      if ([11].includes(formatedSetting.rule_type_id)) { 
        formatedSetting.rule_day = this.optionRuleByDay.filter(day => day.code === setting['day'])
        delete formatedSetting['day']
      }
      return formatedSetting
    },
    formatSettingInput (rule) {
      switch (rule.type.id) {
      case 2:
        return this.formatInputOperator(rule)
      case 4:
        return this.formatInputOperator(rule)
      case 3: 
        return rule.updatePlaces || rule.uploadFile || rule.updatePlacesMx ? rule.input : []
      case 8: 
        return rule.updatePlaces || rule.uploadFile || rule.updatePlacesMx ? rule.input : []
      case 6: 
        return { 'x-rule-by-code': rule.rule_code }
      case 7: 
        return {
          service_name: rule.service_name
        }
      case 9: 
        return { 'x-deadline_date': rule?.rule_day?.code }
      case 10: 
        return rule.input
      case 11: 
        return { 'day': rule?.rule_day?.code }
      case 12: 
        return this.formatInputOperator(rule, 'fieldSelect')
      case 13:
        return this.formatInputOperator(rule)
      default:
        this.$alert(this.$t('msg-problema-cargar-datos'))
        break
      }
    },
    validateSetting (setting) {
      if ([2, 4].includes(setting.type?.id)) {
        return !Number.isNaN(parseFloat(setting.min) + parseFloat(setting.max))
      }
      if (setting.type?.id === 6) {
        return !!setting.rule_code
      }
      if (setting.type?.id === 7) {
        return !!setting.service_name
      }
      if (setting.type?.id === 9) {
        return !!setting.rule_day
      }
      return true
    },
    openModalLocations() {
      this.loading[`location-${this.index}`] = true
      if (this.form.deleteId || !!this[`places-location-condition-${this.conditionIndex}-setting-${this.index}`]) { 
        if (this.country.code === 'MX') {
          this.currentSettingByCode = {
            ...this.form,
            country: this.country
          }
          if (this.settingDisabled) this.openModal(`modalLocations-lock-setting-${this.conditionIndex}-${this.index}`)
          else this.openModal(`modalLocationsByCode-setting-${this.conditionIndex}-${this.index}`)
          this.loading[`location-${this.index}`] = false
        } else {
          this.currentSetting = {
            places: this.form.input,
            country: this.country
          }
          if (this.settingDisabled) this.openModal(`modalLocations-lock-setting-${this.conditionIndex}-${this.index}`)
          else this.openModal(`modalLocations-setting-${this.conditionIndex}-${this.index}`)
          this.loading[`location-${this.index}`] = false
        }
      } else {
        this.ruleService.callService(`get${this.settingRuleService}`, {}, { [this.entity]: this.entity_id, id: this.form.id })
          .then(resp => {
            this[`places-location-condition-${this.conditionIndex}-setting-${this.index}`] = true
            if (this.country.code === 'MX') {
              this.currentSettingByCode = {
                ...resp.data,
                country: this.country
              }
              if (this.settingDisabled) this.openModal(`modalLocations-lock-setting-${this.conditionIndex}-${this.index}`)
              else this.openModal(`modalLocationsByCode-setting-${this.conditionIndex}-${this.index}`)
            } else {
              this.currentSetting = {
                ...resp.data,
                places:  resp.data.rule_type_id === 3 || resp.data.rule_type_id === 8 ? resp.data.input : [],
                country: this.country
              }
              if (this.settingDisabled) this.openModal(`modalLocations-lock-setting-${this.conditionIndex}-${this.index}`)
              else this.openModal(`modalLocations-setting-${this.conditionIndex}-${this.index}`)
            }
          })
          .catch((error) => {
            console.error('Error al obtener places ', error)
            this.$alert(this.$t('msg-wrong-getplaces', {code: error}))
          })
          .finally(() => {
            this.loading[`location-${this.index}`] = false
          })
      }
    },
    openModalUpload () {
      this.currentSettingByUpload = {
        rule_id: this.rule_id,
        country: this.country,
        ...this.setting
      }
      this.openModal(`modalUploadLocation-setting-${this.conditionIndex}-${this.index}`)
    },
    openModal(modal) {
      this.$bvModal.show(modal)
    },
    loadedData () {
      const requiredLevels = this.internalLevels[this.country.code.toLowerCase()].required
      this.country.requiredLevel = Math.max(...requiredLevels)
      if (!['mx'].includes(this.country.code.toLowerCase())) {
        const displayLevels = this.internalLevels[this.country.code.toLowerCase()].display
        const levels = this.$generateArrayPlacesFromTree(this.levels)
        this.selectorsLevel = this.$generatePlacesLevels(levels, Math.max(...requiredLevels), displayLevels)
        this.$store.commit('setFormattedLevels', {...this.selectorsLevel, country: this.country, levels})
      } else {
        this.selectorsLevel = {
          [`level${this.country.requiredLevel}`]: []
        }
        this.$store.commit('setFormattedLevels', {...this.selectorsLevel, country: this.country, levels: []})
      }
    },
    updateSettingPlaces (data) {
      if (!!data.places && Array.isArray(data.places)) { // Places NOT MX
        this.form.input = data.places
        this.form.count_rule_setting_input = data.places.length
        this.form.updatePlaces = true
        this.form.updatePlacesMx = this.form.uploadFile = false
      } else if (!!data.placesMx) { // Places MX
        this.form.input = data.placesMx
        this.form.count_rule_setting_input = data.placesMxLocal.length
        this.form.updatePlacesMx = true
        this.form.updatePlaces = this.form.uploadFile = false
      } else if (!!data.file) { // Places by uploadFile
        this.form.input = data.file
        this.form.uploadFile = true
        this.form.updatePlacesMx = this.form.updatePlaces = false
      }
      this.keyFormRender++
      this.updateSetting('input')
    },
    filterSelectRuleType () {
      return this.optionsRuleType.filter((el) => (!this.usedRuleTypes.includes(el.id) && el.active))
    },
    formatInputOperator (rule, fieldType = null) {
      if (!rule.operator) return {}
      switch (rule.operator?.code) {
      case 'between':
        return {
          operator: rule.operator.code,
          min: fieldType === 'fieldSelect' ? rule.min?.text : this.parseFloatInputRule(rule?.min),
          max: fieldType === 'fieldSelect' ? rule.max?.text : this.parseFloatInputRule(rule?.max)
        }
      default:
        return {
          operator: rule.operator.code,
          max: fieldType === 'fieldSelect' ? rule.max?.text : this.parseFloatInputRule(rule?.max)
        }
      }
    },
    parseFloatInputRule (value) {
      return parseFloat(value) === 0 ? 0 : parseFloat(value) || null
    },
    getPlaceTemplate () {
      this.baseService.getPlaceTemplate({country: this.country.code.toLowerCase()})
    }
  }
}
</script>
<style lang="scss" scoped>
  .main-container-setting {
    &:not(.no-parent) {
      border-left: 1px solid var(--secondary-3);
    }
    &.nesting {
      > .container-setting{
        border: 1px solid var(--light-3);
        box-shadow: 2px 2px 20px 2px var(--light-3);
      }
    }
    &.to-nest:not(.nesting):hover{
      > .container-setting{
        border: 1px solid var(--primary-6);
        box-shadow: 2px 2px 20px 2px var(--primary-6);
        cursor: pointer !important;
      }
    }
    > .container-setting{
      border: 1px solid white;
      margin-top: 1rem;
      border-radius: 0.4rem;
    }
  }
  .main-container-setting-lastone {
    position: relative; 
    height: calc(50% - 15px); 
  }
  .main-container-setting:after,
  .main-container-setting-lastone:before,
  .main-container-setting-lastone:after {
    content: '';
    position: absolute;
    width: 1px; 
    background-color: var(--secondary-3); 
  }
  .main-container-setting-lastone:before {
    top: 0; 
    left: 0; 
    height: 40px;
  }
  .main-container-setting:after,
  .main-container-setting-lastone:after {
    top: 33px;
    left: 7px;
    height: 15px;
    transform: rotate(90deg)
  }
</style>