<template>
  <b-modal
    :id="modalId" :ref="modalId"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t(title)"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <p>Cargue aquí el archivo masivo de localidades de regla.</p>
    <p class="font-weight-bold">
      El archivo solo puede ser *.xls o *.xlsx, según el siguiente
      <a v-show="title === 'Orígenes'" v-if="isMexico" class="lnk lnk-primary" :href="getFileName" download="carga_masiva_localidades_origen.xlsx">excel de ejemplo con formato válido.</a>
      <a v-show="title === 'Destinos'" v-if="isMexico" class="lnk lnk-primary" :href="getFileName" download="carga_masiva_localidades_destino.xlsx">excel de ejemplo con formato válido.</a>

      <a v-show="title === 'Orígenes'" v-if="isChile" class="lnk lnk-primary" href="#" @click="$emit('getPlaceTemplate')">excel de ejemplo con formato válido para Chile.</a>
      <a v-show="title === 'Destinos'" v-if="isChile" class="lnk lnk-primary" href="#" @click="$emit('getPlaceTemplate')">excel de ejemplo con formato válido para Chile.</a>

      <a v-show="title === 'Orígenes'" v-if="isPeru" class="lnk lnk-primary" href="#" @click="$emit('getPlaceTemplate')">excel de ejemplo con formato válido para Peru.</a>
      <a v-show="title === 'Destinos'" v-if="isPeru" class="lnk lnk-primary" href="#" @click="$emit('getPlaceTemplate')">excel de ejemplo con formato válido para Peru.</a>

      <a v-show="title === 'Orígenes'" v-if="isColombia" class="lnk lnk-primary" href="#" @click="$emit('getPlaceTemplate')">excel de ejemplo con formato válido para Colombia.</a>
      <a v-show="title === 'Destinos'" v-if="isColombia" class="lnk lnk-primary" href="#" @click="$emit('getPlaceTemplate')">excel de ejemplo con formato válido para Colombia.</a>
    </p>
    <b-col class="px-0 pb-1 pb-md-0 col">
      <div class="file-drop-area" :class="success">
        <input
          type="file"
          name="fileDataToDisable"
          id="fileDataToDisable"
          class="file-input invisible"
          accept=".xls, .xlsx"
          @change="fileOnChange"
        />
        <label
          for="fileDataToDisable"
          class="w-100 text-center cursor-pointer m-0"
        >
          <feather-icon icon="UploadCloudIcon" size="30" class="mt-1" />
          <p class="mb-1">Subir Archivo</p>
        </label>
      </div>
    </b-col>
    <template #modal-footer>
      <b-button variant="warning" @click.prevent="ok()" :disabled="loading">
        <b-spinner v-show="loading" small></b-spinner>
        {{$t('Guardar')}}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    modalId: { type: String },
    setting: { type: Object },
    loading: { type: Boolean }
  },
  data() {
    return {
      file: null,
      form: null
    }
  },
  computed: {
    title() {
      if (this.setting?.rule_type_id === 3) return 'Destinos'
      return 'Orígenes'
    },
    success() {
      if (this.file) return 'success-box'
      return ''
    },
    getFileName() {
      const local = window.location.origin
      if (this.setting?.rule_type_id === 3) return `${local}/files/carga_masiva_localidades_destino.xlsx`
      return `${local}/files/carga_masiva_localidades_origen.xlsx`
    },
    isMexico() {
      return this.setting && this.setting.country ? this.setting.country.code === 'MX' : false
    },
    isChile() {
      return this.setting && this.setting.country ? this.setting.country.code === 'CL' : false
    },
    isPeru() {
      return this.setting && this.setting.country ? this.setting.country.code === 'PE' : false
    },
    isColombia() {
      return this.setting && this.setting.country ? this.setting.country.code === 'CO' : false
    }
  },
  methods: {
    ok (e) {
      this.form = {
        file: this.file,
        rule_setting_id: this.setting.id,
        rule_id: this.setting.rule_id,
        rule_type_id: this.setting.rule_type_id,
        parent_rule_setting_id: this.setting?.parent_rule_setting_id,
        to: this.$session.get('cas_user').email
      }
      if (this.file) this.$emit('postUploadFile', this.form)
      this.$bvModal.hide(this.modalId)
      // if (!this.isMexico) {
      //   setTimeout(() =>
      //     window.location.reload()
      //   , 1500)
      // }
    },
    fileOnChange(e) {
      this.file = e.target.files[0]
    }
    // fileValidation() {
    //         var fileInput = 
    //             document.getElementById('file');
             
    //         var filePath = fileInput.value;
         
    //         // Allowing file type
    //         var allowedExtensions = 
    //                 /(\.jpg|\.jpeg|\.png|\.gif)$/i;
             
    //         if (!allowedExtensions.exec(filePath)) {
    //             alert('Invalid file type');
    //             fileInput.value = '';
    //             return false;
    //         } 
    //         else 
    //         {
             
    //             // Image preview
    //             if (fileInput.files && fileInput.files[0]) {
    //                 var reader = new FileReader();
    //                 reader.onload = function(e) {
    //                     document.getElementById(
    //                         'imagePreview').innerHTML = 
    //                         '<img src="' + e.target.result
    //                         + '"/>';
    //                 };
                     
    //                 reader.readAsDataURL(fileInput.files[0]);
    //             }
    //         }
    //     }
  }
}
</script>
<style lang="scss" scoped>
div.file-drop-area {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  max-height: 300px;
  overflow-y: auto;
  input.file-input {
    position: absolute;
    height: 0;
  }
}
.success-box {
  border: 1px solid #1f9d57 !important;
}
.error-box {
  border: 1px solid #ea5455 !important;
}
</style>
