<template>
    <b-modal :id="modalId" :ref="modalId"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Localidades por codigo')"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    centered
    @show="onShow"
    @close="onClose"
  >
  <form-render
  :fields.sync="fields"
  :form.sync="myForm"
  :key="`myFormByCode${keyFormRender}`"
  containerButtonsClass="w-100"></form-render>
  <b-form-checkbox
  v-model="check"
  @change="changeCode"
  class="my-2"
  :disabled="loading.locationByCode || loading.addCode || loading.deleteCode || disabled"
  >
  <p>
    <b-spinner v-show="loading.locationByCode || loading.addCode || loading.deleteCode" small class="mx-1"></b-spinner>
    {{selected}}
  </p>
  </b-form-checkbox>
  </b-modal>
</template>
<script>
import BaseServices from '@/store/services'
import FormRender from '@/views/components/custom/form-render/FormRender.vue'
import { mapGetters } from 'vuex'

export default {
  components: { FormRender },
  props: {
    modalId: { type: String },
    setting: { type: Object, default: null },
    entityKey: { type: String, default: '' },
    entityValue: { type: Number, default: 0 }
  },
  data() {
    return {
      fields: [],
      placesToSave: [],
      myForm: {},
      keyFormRender: 0,
      codeLocation: null,
      disabled: true,
      check: false,
      selected: 'Seleccione una localidad',
      loading: {
        locationByCode: false,
        addCode: false,
        deleteCode: false
      },
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    }),
    isOrigin() {
      if (this.setting?.rule_type_id === 3) return false
      return true
    },
    serviceLocation() {
      return this.entityKey === 'shipper_id' ? 'LocationCode' : 'LocationCodeByOrganization'
    },
    serviceChangeLocation() {
      return this.entityKey === 'shipper_id' ? 'ChangeValueCode' : 'ChangeValueCodeByOrganization'
    }
  },
  watch: {
    setting() {
      this.localPlaces = this.setting.input.map(input => input.code)
    },
    generalLoading: {
      handler () {
        this.loading.locationByCode = !!this.generalLoading[`get${this.serviceLocation}`]
        // this.loading.addCode = !!this.generalLoading[`post${this.serviceChangeLocation}`]
        // this.loading.deleteCode = !!this.generalLoading[`delete${this.serviceChangeLocation}`]
      },
      deep: true
    }
  },
  methods: {
    onShow() {
      this.fields = []
      this.fields.push(
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'location', label: 'Buscar localidad', validation: 'required', containerClass: 'col-12 pb-2', clearable: true, change: this.changeLocation, searchOnType: { fx: this.getPlaces, nChar: 3, debounce: 600 }}
      )
      this.keyFormRender++
    },
    getPlaces(value) {
      const params = { country_id: 2 }
      const queryParams = { search: value }
      return this.baseService.callService('getPlacesQuoter', queryParams, params)
        .then(resp => { return resp.data.places.map(el => ({...el, text: el.full_name, valid: true})) })
        .catch(err => { return err })
    },
    changeLocation(name, value) {
      if (value === null) {
        this.check = false
        this.selected = 'Seleccione una localidad'
        this.disabled = true
        return
      }
      this.loading.locationByCode = true
      this.check = false
      this.codeLocation = value.full_name.split(',')[1].split(' ')[2]
      if (this.localPlaces.includes(this.codeLocation)) {
        this.check = true
      } else {
        this.check = false
      } 
      this.selected = value.full_name
      this.disabled = false
      setTimeout(() => {
        this.loading.locationByCode = false
      }, 250) 
    },
    changeCode(value) {
      const places = this.placesToSave.map(place => place.code)
      if (value) {
        this.localPlaces.push(this.codeLocation)
        if (places.includes(this.codeLocation)) {
          this.placesToSave = this.placesToSave.filter(place => place.code !== this.codeLocation)
        } else this.placesToSave.push({code: this.codeLocation, action: 'post'})
      } else {
        this.localPlaces = this.localPlaces.filter(code => code !== this.codeLocation)
        if (places.includes(this.codeLocation)) {
          this.placesToSave = this.placesToSave.filter(place => place.code !== this.codeLocation)
        } else this.placesToSave.push({code: this.codeLocation, action: 'delete'})
      }
    },
    
    onClose() {
      this.$emit('update', { placesMx: this.placesToSave, placesMxLocal: this.localPlaces.map(place => { return { code: place } }) })
      this.placesToSave = []
      this.localPlaces = []
      this.myForm = {}
      this.fields = []
      this.check = false
      this.selected = 'Seleccione una localidad'
      this.disabled = true
    }
  }
    
}
</script>